import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Lightning web React page</h1>
      <p>Hello! follow me on my adventure to learning React n stuff. So far nothing is on here but as I keep on learning something new will pop up. Thank you</p>
    </div>
  );
}

export default App;
